<template>
  <div class="row mx-0 my-5 align-items-center">
    <div class="col-md-5 order-md-first order-last map_text">
      <div class="row m-0 justify-content-center justify-content-md-start">
        <div class="col-12 gold_heading">
          About us
        </div>
        <div class="col-12 green_heading">
          Striving to serve<br/>humanity for over<br/>nineteen years...
        </div>
        <div class="col-12 my-4 text">
          We work in over 75 countries worldwide, delivering aid in crisis and non-crisis situations for over 15 years with a 100% donations policy
        </div>
        <div class="col-auto">
          <Button color="green" btnText="More About Us" icon="arrow" @buttonClicked="goTo('aboutus')" class="mb-1" >
            <IconArrowForward color="gold" size="size20" />
          </Button>
        </div>
      </div>
    </div>
    <div class="col-md-7 p-0 ">
      <img src="@/components/animations/map.gif" v-if="isApp" class="img-fluid" />
      <MapAnimation v-else />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    MapAnimation: defineAsyncComponent(() => import('@/components/animations/MapAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'HomeAbout',
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  },
  computed: {
    ...mapGetters(['isApp'])
  }
}
</script>
<style scoped>
  .gold_heading {
    color: var( --gold-color );
    font-family: "quicksand_bold", Sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 6.4px;
    text-transform: uppercase;
    text-align: center;
  }
  .green_heading {
    color: var( --green-color );
    padding: 9px 0px 0px 0px;
    font-family: "quicksand_bold", Sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
  }

  .map_text {
    margin-top: -3rem;
    z-index: 2;
  }
  .text {
    font-size: 1rem;
    text-align: center;
  }

  @media (min-width: 992px) {
    .gold_heading {
      color: var( --gold-color );
      font-family: "quicksand_bold", Sans-serif;
      font-size: 1.1rem;
      font-weight: bold;
      letter-spacing: 6.4px;
      text-transform: uppercase;
      text-align: left;
    }
    .green_heading {
      color: var( --green-color );
      padding: 9px 0px 0px 0px;
      font-family: "quicksand_bold", Sans-serif;
      font-size: 3.25rem;
      font-weight: bold;
      line-height: 1.4;
      text-align: left;
    }
    .map_text {
      margin-top: 0;
    }
    .text {
      font-size: 1.3rem;
      text-align: left;
    }
  }

</style>
